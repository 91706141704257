import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://games.crossfit.com/"
      }}>{`Open Workout 19.2`}</a></p>
    <p>{`Beginning on an 8-minute clock, complete as many reps as possible of:\\
25 toes-to-bars\\
50 double-unders\\
15 squat cleans, 135 / 85 lb.\\
25 toes-to-bars\\
50 double-unders\\
13 squat cleans, 185 / 115 lb.`}</p>
    <p>{`If completed before 8 minutes, add 4 minutes to the clock and proceed
to:\\
25 toes-to-bars\\
50 double-unders\\
11 squat cleans, 225 / 145 lb.`}</p>
    <p>{`If completed before 12 minutes, add 4 minutes to the clock and proceed
to:\\
25 toes-to-bars\\
50 double-unders\\
9 squat cleans, 275 / 175 lb.`}</p>
    <p>{`If completed before 16 minutes, add 4 minutes to the clock and proceed
to:\\
25 toes-to-bars\\
50 double-unders\\
7 squat cleans, 315 / 205 lb.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We’ll be judging during classes today and throwing down tonight
from 4:30-6:30 so come out to watch and/or participate!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      